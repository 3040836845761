export default function cursorMaskHandler(separator) {
  return (e) => {
    const input = e.target
    const inputValue = input.value

    if (inputValue.length === 0) return

    const choppedInputValue = inputValue.substr(0, input.selectionStart)
    let separatorIndex = choppedInputValue.lastIndexOf(separator)

    // if the separator wasn't found, see if a space is found
    if (separatorIndex < 0) separatorIndex = choppedInputValue.lastIndexOf(' ')

    const segmentStart = (separatorIndex > 0 && separatorIndex + 1) || 0
    const segment = inputValue.substr(segmentStart).split(separator)[0]
    let cursorPosition = segmentStart

    if (segment.match(/^[^_]+$/)) {
      // maintain cursor position
      return
    }

    if (!segment.match(/^_+$/)) {
      const segmentMatch = segment.match(/^[\d(a-z]+/)

      if (segmentMatch) cursorPosition += segmentMatch[0].length
    }

    input.setSelectionRange(cursorPosition, cursorPosition)
  }
}
