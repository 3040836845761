import Alert from 'react-s-alert'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'

import { base64ObjectDecoder } from '@fundrocket/common/libs/base64-utils'
import formatPhone from '@fundrocket/common/libs/format-phone'
import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import verifyPhone from '@fundrocket/common/libs/verify-phone'
import Container from '@fundrocket/common-web/components/Container'
import Display from '@fundrocket/common-web/components/Display'
import Heading from '@fundrocket/common-web/components/Heading'
import LoadingIndicator from '@fundrocket/common-web/components/LoadingIndicatorWomply'
import MediaObject from '@fundrocket/common-web/components/MediaObject'
import Row from '@fundrocket/common-web/components/Row'
import Section from '@fundrocket/common-web/components/Section'
import Text from '@fundrocket/common-web/components/Text'
import View from '@fundrocket/common-web/components/View'

// TODO
import Head from 'components/Head'
import SignInForm from 'components/SignInForm'
import paths from 'constants/paths'
import { UserContext } from 'contexts/User'

import styles from './SignIn.module.scss'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css'
import { ReactComponent as BadgeSvg } from './images/badge.svg'
import { ReactComponent as BbbPciSvg } from './images/bbb-pci.svg'
import { ReactComponent as CheckmarkSvg } from './images/checkmark.svg'
import { ReactComponent as LogoSvg } from './images/logo.svg'
import { ReactComponent as CalendarSvg } from './images/features/calendar.svg'
import { ReactComponent as FeesSvg } from './images/features/fees.svg'
import { ReactComponent as MarkersSvg } from './images/features/markers.svg'

function navigateTo(redirectPath) {
  navigate(redirectPath)
}

export default function SignIn(props) {
  const [checkAuth, setCheckAuth] = React.useState(false)
  const userContext = React.useContext(UserContext)

  React.useEffect(() => {
    const { womplyUserInfo = '' } = props
    const womplyUserInfoDecoded = base64ObjectDecoder(womplyUserInfo)
    const { blid, customer_slug, email, first_name, last_name, role, user_id } =
      womplyUserInfoDecoded || {}
    let redirectPath = paths.APP
    const { search: queryString } = window.location
    const parsedQueryString = qs.parse(queryString, { ignoreQueryPrefix: true })
    redirectPath = parsedQueryString?.redirect || redirectPath

    async function checkAuthStatus() {
      const verifyStatus = await verifyPhone.status()

      if (verifyStatus) {
        reporter
          .analyticsSignIn({
            sub: verifyStatus.user_id,
            $email: email,
            $phone: formatPhone.e164(verifyStatus.phone),
            blid,
            email,
            first_name,
            last_name,
            role,
            user_id,
          })
          .setErrorUser({
            sub: verifyStatus.user_id,
            email,
            phone: formatPhone.e164(verifyStatus.phone),
          })
          .setEventsContext({
            business_location_id: blid,
            consumer_phone: formatPhone.e164(verifyStatus.phone),
            customer_slug,
          })

        userContext.updateUser({ user: true })
        navigateTo(redirectPath)
      } else {
        setCheckAuth(true)
      }
    }

    checkAuthStatus()
  }, [])

  const bbbPci = <BbbPciSvg aria-label="BBB & PCI" className={styles['bbb-pci']} />

  const whys = [
    'Pay expenses with a credit card to keep valuable cash on hand',
    'We charge one industry-low 3% fee, and show you upfront before you send your payment',
    'Earn credit card rewards even when paying business expenses',
    'We use only the best industry-standard security protocols and best practices',
    'Drop the hassle of mailing paper checks',
  ]

  const features = [
    {
      title: 'No hidden fees',
      icon: FeesSvg,
      copy: 'The one-time 3% fee is shown upfront before you pay any bill',
    },
    {
      title: 'Flexible due dates',
      icon: CalendarSvg,
      copy: 'Schedule a payment in minutes, and don’t pay any bill until you’re ready',
    },
    {
      title: 'End-to-end payment tracking',
      icon: MarkersSvg,
      copy: 'Track the status of bill payments in your dashboard',
    },
  ]

  const uses = [
    'Taxes',
    'Rent',
    'Utilities',
    'Suppliers',
    'Vendors',
    'Insurance',
    'Employers',
    'Contractors',
    'Advertising',
    'Accountants',
    'Lawyers',
    'Tax preparers',
  ]

  return checkAuth ? (
    <>
      <Alert effect="stackslide" position="top" timeout={5000} />
      <div className={classNames('SignIn', styles.this)}>
        {/* TODO should use Layout that'd include `Head` */}
        <Head />
        <View className={styles['sign-in']}>
          <Container size="small" className={styles['sign-in--container']}>
            <Row size="small">
              <LogoSvg aria-label="Womply Pay" />
            </Row>
            <Row size="small">
              <Heading level="1">Get started</Heading>
              <Text element="p" flush>
                For enhanced security, we use mobile phone verification. Please verify your number
                to get started with Womply Bills.
              </Text>
            </Row>
            <SignInForm {...props} />
            <Display hide="desktop">{bbbPci}</Display>
          </Container>
        </View>
        <div className={styles.marketing}>
          <Section color="green" container={false} className={styles.headlines}>
            <Container size="large">
              <MediaObject center figure="right" reset="mobile" size="large">
                <>
                  <Row size="xsmall">
                    <Text
                      element="h2"
                      size="xxlarge"
                      weight="bold"
                      className={styles['headlines--large']}>
                      Use your credit card to pay any business, even where cards aren’t accepted
                    </Text>
                  </Row>
                  <Text element="p" flush size="large">
                    Just like your bank’s bill pay system but with your credit card
                  </Text>
                </>
                <Display hide="mobile">
                  <BadgeSvg aria-label="Introducing Bills" />
                </Display>
              </MediaObject>
              <Display show="mobile">
                <BadgeSvg aria-label="Introducing Bills" className={styles.badge} />
              </Display>
            </Container>
          </Section>
          <Section container={false}>
            <Container size="large">
              <Row size="large">
                <Row size="small">
                  <Text element="h2" size="large" weight="bold">
                    Why use Bills?
                  </Text>
                </Row>
                <div className={styles.whys}>
                  {whys.map((item) => (
                    <Text element="p" key={item} className={styles['whys--item']}>
                      {item}
                    </Text>
                  ))}
                </div>
              </Row>
              <Row size="large">
                <Row size="small">
                  <Text element="h2" size="large" weight="bold">
                    Features
                  </Text>
                </Row>
                <div className={styles.features}>
                  {features.map((item) => (
                    <div key={item.title} className={styles['features--item']}>
                      <Row size="xsmall">
                        <item.icon aria-hidden />
                      </Row>
                      <Row size="xxxsmall">
                        <Text element="h2" weight="bold">
                          {item.title}
                        </Text>
                      </Row>
                      <Text element="p" flush>
                        {item.copy}
                      </Text>
                    </div>
                  ))}
                </div>
              </Row>
              <Row size="small">
                <Text element="h2" size="large" weight="bold">
                  Bills lets you pay
                </Text>
              </Row>
              <ul className={styles.uses}>
                {uses.map((item) => (
                  <Text element="li" key={item} className={styles['uses--item']}>
                    <MediaObject className={styles['uses--item--inner']}>
                      <CheckmarkSvg aria-hidden className={styles['uses--item--icon']} />
                      {item}
                    </MediaObject>
                  </Text>
                ))}
              </ul>
              <Display show="desktop">{bbbPci}</Display>
            </Container>
          </Section>
        </div>
      </div>
    </>
  ) : (
    <LoadingIndicator />
  )
}

SignIn.propTypes = {
  womplyUserInfo: PropTypes.string,
}
