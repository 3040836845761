import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Copy from '@fundrocket/common-web/components/Copy'
import Heading from '@fundrocket/common-web/components/Heading'
import Text from '@fundrocket/common-web/components/Text'

import styles from './Fieldset.module.scss'

export default function Fieldset({ children, className, copy, hide, standalone, title }) {
  const headingProps = standalone
    ? {
        level: '1',
        levelStyle: '2',
      }
    : {
        element: 'legend',
      }

  // renders `div` instead of `fieldset` as to not require residing within `form`
  // https://bugs.chromium.org/p/chromium/issues/detail?id=570095#c22
  return (
    <Text
      align="left"
      element="div"
      className={classNames('Fieldset', styles.this, hide && styles['this---hide'], className)}>
      {title && (
        <Heading {...headingProps} className={styles.legend}>
          {title}
        </Heading>
      )}
      {copy && (
        <Copy element="div" size="small" className={styles.copy}>
          {copy}
        </Copy>
      )}
      {children}
    </Text>
  )
}

Fieldset.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  copy: PropTypes.node,
  hide: PropTypes.bool,
  standalone: PropTypes.bool,
  title: PropTypes.node,
}
