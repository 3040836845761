import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'

import inputValues from '@fundrocket/common/constants/input-values'
import { base64ObjectDecoder } from '@fundrocket/common/libs/base64-utils'
import formatPhone from '@fundrocket/common/libs/format-phone'
import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import verifyPhone from '@fundrocket/common/libs/verify-phone'
import Button from '@fundrocket/common-web/components/Button'
import FieldEmail from '@fundrocket/common-web/components/FieldEmail'
import Fieldset from '@fundrocket/common-web/components/Fieldset'
import FieldWithInput from '@fundrocket/common-web/components/FieldWithInput'
import Input from '@fundrocket/common-web/components/Input'
import InputPhoneNumber from '@fundrocket/common-web/components/InputPhoneNumber'
import Text from '@fundrocket/common-web/components/Text'
import assertions from '@fundrocket/common-web/libs/assertions'
import notifications from '@fundrocket/common-web/libs/notifications'

import Help from 'components/Help'
import Link from 'components/Link'
import events from 'constants/events'
import paths from 'constants/paths'
import { UserContext } from 'contexts/User'
//import { withWomplyUserContext } from 'contexts/WomplyUserContext'

const STATUS_EMAIL = 'email'
const STATUS_PHONE = 'phone'
const STATUS_PHONE_VERIFICATION = 'phone-verification'
const STATUS_PHONE_VERIFICATION_LAST_4 = 'phone-verification-last-4'

class SignInForm extends React.Component {
  static contextType = UserContext

  state = {
    email: '',
    emailValid: null,
    phone: '',
    phoneLast4: '',
    phoneVerificationCode: '',
    phoneVerificationResendCodeCount: 0,
    showGetOtp: true,
    showHelp: false,
    signInLoading: false,
    status: STATUS_PHONE,
  }

  queryString

  redirectPath = paths.APP

  userExists = false

  womplyUserInfo = null

  componentDidMount() {
    const { womplyUserInfo } = this.props
    const { search: queryString } = window.location

    if (womplyUserInfo) this.womplyUserInfo = base64ObjectDecoder(womplyUserInfo)

    this.queryString = qs.parse(queryString, { ignoreQueryPrefix: true })
    const redirect = this.queryString?.redirect

    if (redirect) {
      this.redirectPath = redirect
    }

    this.handleData()
  }

  handleData = async () => {
    if (!this.womplyUserInfo) return

    const { redirect } = this.queryString
    const { blid, email } = this.womplyUserInfo

    if (redirect) this.redirectPath = redirect

    if (!email || !blid) return

    const userInfo = await verifyPhone.status({ email, blid })

    if (userInfo?.verified && userInfo?.phone) {
      this.setState({
        phoneLast4: userInfo.phone,
        status: STATUS_PHONE_VERIFICATION_LAST_4,
      })
    }
  }

  handlePhoneFormSubmit = async (e) => {
    e.preventDefault()
    const { phone } = this.state
    this.setState({ signInLoading: true })
    const userInfo = await verifyPhone.status({ phone: formatPhone.e164(phone) })
    if (userInfo?.email) {
      this.verifyPhone()
    } else {
      this.setState({
        signInLoading: false,
        status: STATUS_EMAIL,
      })
    }
  }

  handlePhoneVerificationFormBack = () => {
    this.setState({ status: STATUS_PHONE })
  }

  handleSendNewPhoneVerificationCode = () => {
    this.setState(
      (prevState) => ({
        phoneVerificationCode: '',
        phoneVerificationResendCodeCount: prevState.phoneVerificationResendCodeCount + 1,
      }),
      () => {
        reporter.trackEvent(events.PHONE_VERIFICATION_RESEND, {
          phoneVerificationResendCodeCount: this.state.phoneVerificationResendCodeCount,
        })
      }
    )

    this.verifyPhone()
    notifications.notify({
      level: 'info',
      message: 'Code sent',
    })

    if (this.phoneVerificationForm) this.phoneVerificationForm.focusInput()
  }

  handlePhoneVerificationFormSubmit = (e) => {
    e.preventDefault()
    this.setState({ signInLoading: true })
    const { phone, phoneLast4, phoneVerificationCode } = this.state
    const { blid, customer_slug, email, first_name, last_name, role, user_id } =
      this.womplyUserInfo || {}

    verifyPhone.submit({
      phone: phone && formatPhone.e164(phone),
      phoneLast4,
      phoneVerificationCode,
      params: {
        blid,
        email,
      },
      onError: (validation) => {
        this.setState({
          phoneVerificationValidation: validation,
          signInLoading: false,
        })

        if (this.phoneVerificationForm) this.phoneVerificationForm.focusInput()
      },
      onSuccess: ({ user }) => {
        this.context.updateUser({ user: true })
        this.setState({ signInLoading: false })

        if (!this.userExists) {
          reporter.analyticsSignUp(user.user_id)
        }

        reporter
          .analyticsSignIn({
            sub: user.user_id,
            $email: email,
            $phone: formatPhone.e164(user.phone),
            blid,
            email,
            first_name,
            last_name,
            role,
            user_id,
          })
          .setErrorUser({
            sub: user.user_id,
            email,
            phone: formatPhone.e164(user.phone),
          })
          .setEventsContext({
            business_location_id: blid,
            consumer_phone: formatPhone.e164(user.phone),
            customer_slug,
          })
          .addGuides({
            blid,
            email,
          })

        if (!this.userExists) {
          reporter.trackEvent(events.SIGN_UP, {
            phone: formatPhone.e164(user.phone),
            email,
            blid,
          })
        }

        reporter.trackEvent(events.PHONE_VERIFICATION_SUCCESS, {
          phone: formatPhone.e164(user.phone),
          email,
          blid,
        })

        // TODO: to lib
        if (!_.isUndefined(window.FS)) {
          window.FS.identify(blid, {
            email,
            phone: formatPhone.digits(phone),
          })
        }

        this.navigate()
      },
    })
  }

  handleEmailFormSubmit = (e) => {
    e.preventDefault()
    this.womplyUserInfo = { ...this.womplyUserInfo, ...{ email: this.state.email } }
    this.setState(
      {
        status: STATUS_PHONE_VERIFICATION,
      },
      () => this.verifyPhone()
    )
  }

  navigate = () => {
    navigate(this.redirectPath)
  }

  verifyPhone = async () => {
    const { phone, phoneLast4 } = this.state
    const { blid, email } = this.womplyUserInfo || {}

    const commonArgs = {
      params: {
        blid,
        email,
      },
      phone,
      phoneLast4,
      onSuccess: () => {
        this.setState({
          signInLoading: false,
          status: phoneLast4 ? STATUS_PHONE_VERIFICATION_LAST_4 : STATUS_PHONE_VERIFICATION,
        })
      },
    }

    if (phoneLast4) {
      verifyPhone.verify({
        ...commonArgs,
        signUp: false,
        onError: ({ validation }) => {
          if (validation) {
            notifications.notify({
              level: 'error',
              message: validation,
            })
          }
        },
      })

      return
    }

    let phonePresent = false

    if (await verifyPhone.status({ phone: formatPhone.e164(phone) })) {
      phonePresent = true
      this.userExists = true
    }

    if (!phonePresent && !blid) {
      this.setState({ signInLoading: false })
      navigate(paths.FORBIDDEN)
    } else {
      verifyPhone.verify({
        ...commonArgs,
        signUp: phonePresent ? undefined : true,
        onError: ({ validation }) => {
          this.setState({ signInLoading: false })
          if (validation) {
            notifications.notify({
              level: 'error',
              message: validation,
            })
            this.setState({ status: STATUS_PHONE })
          }
        },
      })
    }
  }

  handleNotMyPhoneQuery = () => {
    this.setState({
      showHelp: true,
    })
  }

  handleRequestOTP = () => {
    this.verifyPhone()
    this.setState({
      showGetOtp: false,
    })
  }

  getOTPCopy = (showOTPButton) => {
    const { phone, phoneLast4, phoneVerificationResendCodeCount, showHelp, status } = this.state
    const isVerificationWithLast4 = status === STATUS_PHONE_VERIFICATION_LAST_4

    if (showOTPButton) {
      return (
        <>
          <p>
            We will send a 4-digit code to&nbsp;
            <Text weight="bold">{isVerificationWithLast4 ? `(###) ###-${phoneLast4}` : phone}</Text>
            .
          </p>
          {isVerificationWithLast4 && (
            <p>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link onClick={this.handleNotMyPhoneQuery}>Need help?</Link>
              {showHelp && <Help />}
            </p>
          )}
          <Button
            level="primary"
            type="button"
            testid="sign-in-form--get-otp-button"
            onClick={this.handleRequestOTP}>
            Request Code
          </Button>
        </>
      )
    }
    return (
      <>
        <p>
          We sent a 4-digit code to&nbsp;
          <Text weight="bold">{isVerificationWithLast4 ? `(###) ###-${phoneLast4}` : phone}</Text>.
          {phoneVerificationResendCodeCount < inputValues.PHONE_VERIFICATION_RETRY_MAX && (
            <>
              <br />
              Didn’t get a code? {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link onClick={this.handleSendNewPhoneVerificationCode}>Resend code</Link>.
            </>
          )}
        </p>
        {isVerificationWithLast4 && (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link onClick={this.handleNotMyPhoneQuery}>Need help?</Link>
            {showHelp && <Help />}
          </>
        )}
      </>
    )
  }

  renderPhoneForm() {
    const { phone, signInLoading } = this.state

    return (
      <form onSubmit={this.handlePhoneFormSubmit}>
        <FieldWithInput
          input={InputPhoneNumber}
          inputProps={{
            autoComplete: 'tel-national',
            autoFocus: true,
            //inputRef: (el) => { this.phoneInput = el },
            name: 'phone',
            value: phone,
            testid: 'sign-in-form--phone-input',
          }}
          label="Mobile Phone"
          single
          validate={(e) => {
            assertions.required(e.target.value)
            assertions.phoneNumber(e.target.value)
          }}
          onInvalidChange={(e) => {
            this.setState({
              phone: e.target.value,
              phoneValid: false,
            })
          }}
          onValidChange={(e) => {
            this.setState({
              phone: e.target.value,
              phoneValid: true,
            })
          }}>
          <Button
            disabled={!this.state.phoneValid}
            loading={signInLoading}
            level="primary"
            type="submit"
            testid="sign-in-form--submit-button"
            onClick={this.handlePhoneFormSubmit}>
            Get Started
          </Button>
        </FieldWithInput>
      </form>
    )
  }

  renderPhoneVerificationForm() {
    const {
      phoneVerificationCode,
      phoneVerificationValidation,
      showGetOtp,
      signInLoading,
      status,
    } = this.state
    const isVerificationWithLast4 = status === STATUS_PHONE_VERIFICATION_LAST_4
    const showOTPButton = isVerificationWithLast4 && showGetOtp

    return (
      <form onSubmit={this.handlePhoneVerificationFormSubmit}>
        <Fieldset
          title={
            showOTPButton
              ? 'Request a text message for verification'
              : 'Check your phone for a text message'
          }
          copy={this.getOTPCopy(showOTPButton)}>
          {!showOTPButton && (
            <>
              <FieldWithInput
                error={phoneVerificationValidation}
                input={Input}
                inputProps={{
                  autoComplete: 'one-time-code',
                  autoFocus: true,
                  inputRef: (el) => {
                    this.phoneVerificationCodeInput = el
                  },
                  maxLength: inputValues.PHONE_VERIFICATION_CODE_LENGTH,
                  placeholder: '1234',
                  type: 'tel',
                  value: phoneVerificationCode,
                  testid: 'sign-in-form--code-input',
                }}
                label="Code"
                single
                validate={(e) => {
                  assertions.required(e.target.value)
                  assertions.integer(e.target.value)
                  assertions.length(e.target.value, inputValues.PHONE_VERIFICATION_CODE_LENGTH)
                }}
                onInvalidChange={(e) =>
                  this.setState({
                    phoneVerificationCode: e.target.value,
                    phoneVerificationCodeValid: false,
                  })
                }
                onValidChange={(e) =>
                  this.setState({
                    phoneVerificationCode: e.target.value,
                    phoneVerificationCodeValid: true,
                    phoneVerificationValidation: '',
                  })
                }>
                <Button
                  disabled={!this.state.phoneVerificationCodeValid}
                  level="primary"
                  loading={signInLoading}
                  type="submit"
                  testid="sign-in-form--phone-verification-code-submit-button"
                  onClick={this.handlePhoneVerificationFormSubmit}>
                  Get Started
                </Button>
              </FieldWithInput>
            </>
          )}
        </Fieldset>
      </form>
    )
  }

  renderEmailForm() {
    const { email, emailValid } = this.state
    return (
      <form onSubmit={this.handleEmailFormSubmit}>
        <Fieldset title="Add your email">
          <FieldEmail
            value={email}
            onInvalidChange={(e) => {
              this.setState({
                email: e.target.value,
                emailValid: false,
              })
            }}
            onValidChange={(e) => {
              this.setState({
                email: e.target.value,
                emailValid: true,
              })
            }}
          />
          <Button
            disabled={!emailValid}
            level="primary"
            type="submit"
            testid="sign-in-form--submit-email-button"
            onClick={this.handleEmailFormSubmit}>
            Next
          </Button>
        </Fieldset>
      </form>
    )
  }

  render() {
    const { className } = this.props
    const { status } = this.state

    return (
      <div className={classNames('SignInForm', className)}>
        {status === STATUS_EMAIL && this.renderEmailForm()}
        {status === STATUS_PHONE && this.renderPhoneForm()}
        {status !== STATUS_PHONE && status !== STATUS_EMAIL && this.renderPhoneVerificationForm()}
      </div>
    )
  }
}

SignInForm.propTypes = {
  className: PropTypes.string,
  womplyUserInfo: PropTypes.string,
}

export default SignInForm
