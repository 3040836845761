export default {
  ADD_RECIPIENT_START: 'Bills-AddRecipientStarted',
  ADD_RECIPIENT_ADDRESS_VERIFICATION_SUCCESS: 'Bills-AddRecipientAddressVerificationSuccess',
  ADD_RECIPIENT_ADDRESS_VERIFICATION_FAIL: 'Bills-AddRecipientAddressVerificationFail',
  ADD_RECIPIENT_SUCCESS: 'Bills-AddRecipientSucceeded',
  ADD_RECIPIENT_FAIL: 'Bills-AddRecipientFailed',

  CARD_LINK_START: 'Bills-CardLinkStarted',
  CARD_LINK_SUCCESS: 'Bills-CardLinkSucceeded',
  CARD_LINK_FAIL: 'Bills-CardLinkFailed',

  CREATE_PAYMENT_VERIFY: 'Bills-CreatePaymentVerified',
  CREATE_PAYMENT_START: 'Bills-CreatePaymentStarted',
  CREATE_PAYMENT_SUCCESS: 'Bills-CreatePaymentSucceeded',
  CREATE_PAYMENT_FAIL: 'Bills-CreatePaymentFailed',

  PHONE_VERIFICATION_RESEND: 'Bills-PhoneVerificationResentCode',
  PHONE_VERIFICATION_SUCCESS: 'Bills-PhoneVerificationSucceeded',

  SIGN_UP: 'Bills-SignUp',
}
