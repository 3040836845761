import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Container from '@fundrocket/common-web/components/Container'

import styles from './Section.module.scss'

export default function Section({ children, className, color, container, ...props }) {
  const body = container ? <Container>{children}</Container> : children

  return (
    <div
      {...props}
      className={classNames('Section', styles.this, color && styles[`this---${color}`], className)}>
      {body}
    </div>
  )
}

Section.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['tan', 'green', 'blue', 'white']),
  container: PropTypes.bool,
}

Section.defaultProps = {
  container: true,
}
